<template>
    <div v-if="result.login_n_id!=row.n_id">
          <a title="Active"
           v-if="row['n_active']==1"
           @click="activateControl(row)"
             data-type="org_user_activate"
          >
            <v-icon left>mdi-account</v-icon>
        </a>
        <a title="Inactive"
           v-if="row['n_active']==2"
           @click="activateControl(row)"
           data-type="org_user_inActivate"
        >
            <v-icon left>mdi-account-off-outline</v-icon>
        </a>
    </div>
    <div v-else>
      <a style="cursor: not-allowed;"
       ><v-icon
              left
              disabled
      >mdi-account</v-icon>
       </a>
    </div>
</template>
<script>
   export default {

    methods:{
      activateControl(row){
        let status = 2;
        if(row.n_active==2){
          status = 1;
        }
       let options = {
          function: "activateUserControl",
          n_id: row.n_id,
          n_id_key:row.n_id_key,
          status:status,
          dataType: "json",
          requestType: "ajax"
        };
       let caller = this;
      // let nID = row.n_id;
      // let rowSet = row;
          this.frameworkAxiosRequest({
            method: 'POST',
            url: 'serve.php?f=administration&f2=organisationUserOverview',
            data: options,
          })
              .then(function(response) {
              //update the header extra
              caller.$set(caller.result, "headerExtraInfo",  response.data.result.headerExtraInfo);
              //let currentTable = caller.currentTable;
               caller.updateTable(response.data.result.table,null);
              // currentTable.data.forEach((element, i) => {
              //   if (element.n_id == nID) {
              //       caller.$set(currentTable.data[i], "n_active", status);
              //       caller.$set(rowSet, "singleRowAttr", { class:"" });
              //        if(status==2){//inactive!
              //         caller.$set(rowSet, "singleRowAttr", { class:"grey" });
              //         }
              //   }
              // });
              //caller.userCountUpdate();
             // caller.$store.commit("tableChanged", currentTable);
              caller.$notify({
                group: "foo",
                text: "Successfully updated",
                duration: 2000,
                type: "success",
                speed: 600,
              });
             })
            .catch(function() {
              //handle error
            });
     },
/*
      userCountUpdate(){
        let options = {
          function: "getUserCount",
          dataType: "json",
          requestType: "ajax"
        };
          let caller = this;

        this.frameworkAxiosRequest({
          method: "POST",
          url: "serve.php?f=administration&f2=organisationUserOverview",
          data: options
        })
          .then(function(response) {
            let active = response.data.result.json['activeUser'][0].activeUser;
            let nonActive = response.data.result.json['nonActiveUser'][0].nonActiveUser;
            let totalUser = parseInt(active)+parseInt(nonActive);
            let result = { details: { User:totalUser,
                                      Active:totalUser-nonActive,
                                      Inactive: nonActive
                                    }};
            caller.$set(caller.result, "headerExtraInfo",  result);
          })
          .catch(function() {
            //handle error
          });
        }
*/
    },
    data: function () {
      return {

      };
    },

    props: ["row", "value", "valueKey", "currentTable","result"],

  };
</script>